import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddFavoritesRoutingModule } from './add-favorites-routing.module';
import { AddFavoritesComponent } from './add-favorites/add-favorites.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SHARED_NG_BOOTSTRAP } from '../shared-ng-bootstrap.module';

@NgModule({
  declarations: [
    AddFavoritesComponent
  ],
  imports: [
    CommonModule,
    AddFavoritesRoutingModule,
    SHARED_NG_BOOTSTRAP,
    NgbModule
  ],
  exports: [
    AddFavoritesComponent
  ]
})
export class AddFavoritesModule { }
