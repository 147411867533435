// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // SERVER_URL: 'http://127.0.0.1:3000/',
  SERVER_URL: 'https://www.northbloomfield.com:3000/', //'https://northbloomfield.com:3000/',// https://nbf.onlineprojectdemo.net:3000/
  RECAPTCHA_V3_SITE_KEY:'6LdSgYUgAAAAAEy46UXuGEcToCCsYfuLaiwH6dH3',//production 
  // RECAPTCHA_V3_SITE_KEY:'6LedfvgcAAAAAL0PmDgpR-CQ7AulsrwbQCyqrjof',//demo sever
  MAP_ACCESS_KEY:'AIzaSyBsBSF1iikmML9mB48YU98Y4IcOYmA-ObI',
  BUCKET:'northbloomfield'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
