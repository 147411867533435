import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MakeOfferRoutingModule } from './make-offer-routing.module';
import { MakeOfferComponent } from './make-offer/make-offer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SHARED_NG_BOOTSTRAP } from '../shared-ng-bootstrap.module';
import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  declarations: [
    MakeOfferComponent
  ],
  imports: [
    CommonModule,
    MakeOfferRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SHARED_NG_BOOTSTRAP,
    NgxMaskModule.forRoot()
  ],
  exports: [
    MakeOfferComponent
  ]
})
export class MakeOfferModule { }
