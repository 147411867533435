import { Component, OnInit, OnDestroy, Input, HostListener } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponsiveService, AppScreenOrientation } from 'src/app/core/ui/responsive/responsive.service';
import { webPage } from 'src/app/web-page.data';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PlatformService } from 'src/app/core/platform/platform.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  navbarfixed: boolean = false;
  @HostListener('window:scroll', ['$event']) onscroll() {
    if (this.platformService.isPlatformBrowser) {
      const win = this.responsiveService.getWindow()
      if (win) {
        if (win.scrollY > 100) {
          this.navbarfixed = true
        }
        else {
          this.navbarfixed = false
        }
      }
    }
  }

  @Input() data: { url_name: string, page_title: string }[] | undefined;

  navToggle = false;
  screenOrientationEnumInstance = AppScreenOrientation;
  screenOrientation: AppScreenOrientation | undefined;
  screenOrientationSubcription: Subscription | undefined;
  destroy$ = new Subject<boolean>();
  constructor
    (
      private responsiveService: ResponsiveService,
      protected router: Router,
      private platformService: PlatformService,
      private cookieService: CookieService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.setInitialScreenOrientation();
    this.setScreenOrientationListener();
  }
  footer: { url_name: string, page_title: string }[] = webPage;

  toggleNav() {
    this.navToggle = !this.navToggle;
  }

  private setInitialScreenOrientation() {
    this.screenOrientation = this.responsiveService.screenOrientation;
  }

  /**
   * @description Sets up a screen orientation listener and updates the value for
   * `screenOrientation` in every new event.
   */
  private setScreenOrientationListener() {
    if (this.platformService.isPlatformBrowser) {
      this.screenOrientationSubcription = this.responsiveService.screenOrientation$
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (value: AppScreenOrientation) => {
            this.screenOrientation = value;
          },
          error: () => {
            console.log('Error Breakpoints observable');
          }

        });
    }
  }
  redirectmaintenance() {
    this.router.navigate([`applications/MaintenanceRequest`]);
  }
  redirectFavourites(){
    console.log(this.cookieService.get('FavoriteProperties'))
      this.router.navigate(['properties/favourites'],{queryParams: {searchKeyword: `ids*,${this.cookieService.get('FavoriteProperties')}`}} );
      this.toggleNav();
  }
  redirectHomeforrent() {
    // this.router.navigate([`properties/HomeForRent`]);
    this.toggleNav();
  }
  redirectTocontactUs() {
    // this.router.navigate([`applications/ContactUs`]);
    this.toggleNav();

  }
  redirect(url_name: any) {
    // this.router.navigate([`contents/${url_name}`]);
    this.toggleNav();
  }
  redirectsmaintenance() {
    // this.router.navigate([`applications/MaintenanceRequest`]);
    this.toggleNav();

  }
  redirectsapplyOnline() {
    // this.router.navigate([`applications/ApplyOnline`]);
    this.toggleNav();
  }



}

