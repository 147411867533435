<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="agent-widget">
    <div class="sidebar-widgets">
        <h4>Inquire Now</h4>
        <form [formGroup]="inquireForm" (ngSubmit)="inquire()">
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Your Full Name" formControlName="fullname" />
                <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="inquireForm.get('fullname')?.dirty && inquireForm.get('fullname')?.invalid" class="text-left">
                    <div *ngIf="inquireForm.get('fullname')?.errors?.pattern">Fullname is invalid.</div>
                    <div *ngIf="inquireForm.get('fullname')?.errors?.required">Fullname is required.</div>
                </ngb-alert>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Your Email" formControlName="email" />
                <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="inquireForm.get('email')?.dirty && inquireForm.get('email')?.invalid" class="text-left">
                    <div *ngIf="inquireForm.get('email')?.errors?.pattern">Email is invalid.</div>
                    <div *ngIf="inquireForm.get('email')?.errors?.required">Email is required.</div>
                </ngb-alert>
            </div>
            <div class="form-group">
                <input type="text" class="form-control" placeholder="Your Phone" formControlName="phone"
                    mask="000-000-0000" [showMaskTyped]="false" />
                <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="inquireForm.get('phone')?.dirty && inquireForm.get('phone')?.invalid" class="text-left">
                    <div *ngIf="inquireForm.get('phone')?.errors?.required">Phone is required.</div>
                    <div *ngIf="inquireForm.get('phone')?.errors?.mask">Phone number is invalid.</div>
                </ngb-alert>
            </div>
            <div class="form-group">
                <textarea class="form-control" placeholder="I'm interested in this property."
                    formControlName="description"></textarea>
                <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="inquireForm.get('description')?.dirty && inquireForm.get('description')?.invalid"
                    class="text-left">
                    <div *ngIf="inquireForm.get('description')?.errors?.required">Description is required.</div>
                </ngb-alert>
            </div>
            <button type="submit" class="btn btn-theme full-width">Send Message</button>
        </form>
    </div>
</div>

<ng-template #dangerTpl>
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z" />
    </svg>
    Operation Failed!
</ng-template>