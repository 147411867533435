import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {
  loading = false;
  toggleSwitcher = false;
  skin = 'green-skin';
  constructor() { }

  ngOnInit(): void {
  }

  /**
   * @description Sets the skin for the entire application
   * @param skin : string, basically a css class
   */
  public setSkin(value: string) {
    this.skin = value;
  }

}
