import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone'

@Component({
  selector: 'app-schedule-viewer',
  templateUrl: './schedule-viewer.component.html',
  styleUrls: ['./schedule-viewer.component.css']
})
export class ScheduleViewerComponent implements OnInit {

  @Input() public scheduleData:any;
  constructor( private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log(this.scheduleData)
  }

  getDateWithTimeZone(date:any) {
    return moment(date).tz('America/New_York').format('hh:mm a')
    // return moment(date).format('hh:mm a')
  }

}
