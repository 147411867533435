import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Property } from 'src/app/models/property/property';
import { Router } from '@angular/router';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyCardComponent implements OnInit {
  @Input() data: Property | undefined;

  constructor(protected router: Router,) { }

  ngOnInit(): void {
  }

  async propertyDetail(data: any) {
    this.createSlug()
    const add = await this.createSlug(data.address,data.place,data.city);
    // return `properties/property_details/${data.id}/${add}`
    this.router.navigate(['properties/property_detail', data.id, add]);
  }
  async createSlug(address = null, place = null, city = null) {
    let stringArr:any = [];
    if (address) stringArr.push(address)
    if (place) stringArr.push(place)
    if (city) stringArr.push(city)

    return stringArr.join('_').replace(/[^A-Za-z0-9-]+/g, '-');
  }
}
