import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertySearchComponent } from './property-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SHARED_NG_BOOTSTRAP } from '../shared-ng-bootstrap.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";



@NgModule({
  declarations: [
    PropertySearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    SHARED_NG_BOOTSTRAP,
    NgSelectModule,
    RouterModule,
    NgxSpinnerModule
  ],
  exports: [
    PropertySearchComponent
  ]
})
export class PropertySearchModule { }
