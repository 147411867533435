import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PropertyInfoRoutingModule } from './property-info-routing.module';
import { PropertyInfoComponent } from './property-info/property-info.component';


@NgModule({
  declarations: [
    PropertyInfoComponent
  ],
  imports: [
    CommonModule,
    PropertyInfoRoutingModule
  ],
  exports: [
    PropertyInfoComponent
  ]
})
export class PropertyInfoModule { }
