import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PropertyCardModule } from './property-card/property-card.module';
import { CityFinderModule } from './city-finder/city-finder.module';
import { InfoCardModule } from './info-card/info-card.module';
import { InfoListTileModule } from './info-list-tile/info-list-tile.module';
import { ProfileCardModule } from './profile-card/profile-card.module';
import { SHARED_NG_BOOTSTRAP } from './shared-ng-bootstrap.module';
import { SwiperModule } from 'swiper/angular';
import { PropertySearchModule } from './property-search/property-search.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PropertyInfoModule } from './property-info/property-info.module'
import { InquireModule } from './inquire/inquire.module'
import { ApplyOnlineModule } from './apply-online/apply-online.module'
import { LocationMapModule } from './location-map/location-map.module'
import { AddFavoritesModule } from './add-favorites/add-favorites.module'
import { EmailFriendModule } from './email-friend/email-friend.module'
import { MakeOfferModule } from './make-offer/make-offer.module'
import { ViewScheduleModule } from './view-schedule/view-schedule.module'
const THIRD_PARTY_MODULES = [
  ...SHARED_NG_BOOTSTRAP,
  SwiperModule,
  FontAwesomeModule,
];
const INHOUSE_FEATURE_MODULES = [
  PropertyCardModule,
  CityFinderModule,
  InfoCardModule,
  InfoListTileModule,
  ProfileCardModule,
  PropertySearchModule,
  PropertyInfoModule,
  InquireModule,
  ApplyOnlineModule,
  LocationMapModule,
  AddFavoritesModule,
  EmailFriendModule,
  MakeOfferModule,
  ViewScheduleModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    ...INHOUSE_FEATURE_MODULES,
    ...THIRD_PARTY_MODULES,
  ],
  exports: [
    CommonModule,
    RouterModule,
    ...INHOUSE_FEATURE_MODULES,
    ...THIRD_PARTY_MODULES
  ]
})
export class SharedModule { }
