import { Component, ChangeDetectionStrategy,OnInit, Input } from '@angular/core';
import {  isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewScheduleService } from '../services/view-schedule.service'
import { ResponsiveService } from 'src/app/core/ui/responsive/responsive.service';
import { ScheduleViewerComponent } from '../schedule-viewer/schedule-viewer.component';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-view-schedule',
  templateUrl: './view-schedule.component.html',
  styleUrls: ['./view-schedule.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewScheduleComponent implements OnInit {
  @Input() public propertyData: any;
  constructor(private modalService: NgbModal, protected activatedRoute: ActivatedRoute, private propertyScheduleService: ViewScheduleService, protected router: Router, private responsiveService: ResponsiveService) { }
  propertyLink: string | undefined;
  propertyAddress: string | undefined;
  ngOnInit(): void {
    this.propertyAddress = this.propertyData.address;
    const win = this.responsiveService.getDocument()
    if(win){  this.propertyLink=win.location.href }
    this.propertyScheduleService.getPropertyScheduleList({ propertyId: this.propertyData.id }).subscribe((result) => {
      if (result.data.resultData) {
        this.events = result.data.resultData.map((element: any) => {
          return {
            start: new Date(`${element.PropertySchedulesMaster.start_datetime}`),
            end: new Date(`${element.PropertySchedulesMaster.end_datetime}`),
            title: this.propertyAddress,
            color: colors.yellow
          }
        })
        this.refresh.next();
      }
    })
  }

  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  activeDayIsOpen: boolean = false;
  CalendarView = CalendarView;
  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  modalData: {
    action: string;
    event: CalendarEvent;
  } | undefined;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    // if (isSameMonth(date, this.viewDate)) {
    //   if (
    //     (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
    //     events.length === 0
    //   ) {
    //     // this.activeDayIsOpen = false;
    //   } else {
    //     // this.activeDayIsOpen = true;
    //   }
    //   this.viewDate = date;
    // }
    // this.setView(CalendarView.Day)
    if(events.length !== 0) {
      const modalRef = this.modalService.open(ScheduleViewerComponent,{
        centered: true
      })
      modalRef.componentInstance.scheduleData = events
    }
    


  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.setView(CalendarView.Day)
    // this.close()
    // this.router.navigate(['properties/details', this.propertyData.id, this.propertyData.address]);
  }

  close() {
    this.modalService.dismissAll()
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
