import { NgModule } from '@angular/core';
import { DefaultComponent } from './default/default.component';
import { HeaderComponent } from './default/header/header.component';
import { NavigationComponent } from './default/navigation/navigation.component';
import { FooterComponent } from './default/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { ToastContainerComponent } from './default/toast-container/toast-container.component';



@NgModule({
  declarations: [
    DefaultComponent,
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    ToastContainerComponent
  ],
  imports: [
    SharedModule,
  ]
})
export class LayoutModule { }
