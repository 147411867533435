import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-city-finder',
  templateUrl: './city-finder.component.html',
  styleUrls: ['./city-finder.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CityFinderComponent implements OnInit {
  @Input() data:  { url_name: string, page_title: string }[] | undefined;
  constructor(protected router: Router) { }

  ngOnInit(): void {
  }
  public propertyTrackByFn(index: number, property: { url_name: string, page_title: string }) {
    return property.page_title;
  }
  city(data:any){
    this.router.navigate([`${data}`]);
  }
}
