import { NgModule } from '@angular/core';

import { RoutesRoutingModule } from './routes-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ThankYouComponent } from './thank-you/thank-you.component';


@NgModule({
  declarations: [
  
    ThankYouComponent
  ],
  imports: [
    SharedModule,
    RoutesRoutingModule
  ]
})
export class RoutesModule { }
