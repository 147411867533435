import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { AppHttpService } from 'src/app/core/net/http/app-http.service';
@Injectable({
  providedIn: 'root'
})
export class InquireService extends AppHttpService {
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient) {
    super(httpClient);
  }

  getInquireForm() {
    return this.formBuilder.group({
      fullname: [null, Validators.required],
      email: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      phone: [null, Validators.required],
      description: [null, Validators.required]
    });
  }

  public sendInquiry(data: {fullname: string, email: string, contact: number, comment: string, property_link: string, property_address: string }): Observable<any> {
    return this.post('web-api/inquiry/create', data);
  }

}
