import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CityFinderComponent } from './city-finder.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    CityFinderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    
  ],
   exports: [
     CityFinderComponent
    ]
})
export class CityFinderModule { }
