<div class="" [ngClass]="skin">

    <!---------- Preloader Start style you can find in spinners.css ---------->
    <div id="preloader" *ngIf="loading">
        <div class="preloader"><span></span><span></span></div>
    </div>
    <!---------- Preloader End ---------->

    <!---------- Main Layout Start ---------->
    <div id="main-wrapper">
        <app-header></app-header>
        <app-navigation></app-navigation>
        <router-outlet></router-outlet>
        <app-footer></app-footer>

        <!---------- Backtotop Start ---------->
        <a id="back2Top" class="top-scroll" title="Back to top" href="#"><i class="ti-arrow-up"></i></a>
        <!---------- Backtotop End ---------->
    </div>
    <!---------- Main Layout End ---------->


    <!---------- Color Switcher Start ---------->
    <!-- <div class="style-switcher" [ngClass]="{'active': toggleSwitcher }">
        <div class="css-trigger"><a (click)="toggleSwitcher = !toggleSwitcher;">
                <fa-icon icon="cog"></fa-icon>
            </a></div>
        <div>
            <ul id="themecolors" class="m-t-20">
                <li><a (click)="setSkin('default-skin')" data-skin="default-skin" class="default-theme">1</a></li>
                <li><a (click)="setSkin('red-skin')" data-skin="red-skin" class="red-theme">2</a></li>
                <li><a (click)="setSkin('green-skin')" data-skin="green-skin" class="green-theme">3</a></li>
                <li><a (click)="setSkin('blue-skin')" data-skin="blue-skin" class="blue-theme">4</a></li>
                <li><a (click)="setSkin('yellow-skin')" data-skin="yellow-skin" class="yellow-theme">5</a></li>
                <li><a (click)="setSkin('purple-skin')" data-skin="purple-skin" class="purple-theme">6</a></li>
                <li><a (click)="setSkin('oceangreen-skin')" data-skin="oceangreen-skin" class="oceangreen-theme">7</a>
                </li>
                <li><a (click)="setSkin('goodgreen-skin')" data-skin="goodgreen-skin" class="goodgreen-theme">8</a></li>
                <li><a (click)="setSkin('goodred-skin')" data-skin="goodred-skin" class="goodred-theme">9</a></li>
                <li><a (click)="setSkin('blue2-skin')" data-skin="blue2-skin" class="blue2-theme">10</a></li>
            </ul>
        </div>
    </div> -->
    <!---------- Color Switcher End ---------->

    <!-- Toast Container Start -->
    <app-toast-container aria-live="polite" aria-atomic="true"></app-toast-container>
    <!-- Toast Container End -->
</div>