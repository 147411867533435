import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-favorites',
  templateUrl: './add-favorites.component.html',
  styleUrls: ['./add-favorites.component.css']
})
export class AddFavoritesComponent implements OnInit {
  @Input() public flag:any;
  constructor( private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  close(){
    this.modalService.dismissAll()
  }
}
