<div *ngIf="!data" class="single-items">
    <div class="property_item classical-list">
        No Data
    </div>
</div>
<div *ngIf="data" class="block-wrap">
    <div class="block-header">
        <h4 class="block-title">Property Info</h4>
    </div>
    <div class="block-body">
        <ul class="dw-proprty-info">
            <li><strong>Bedrooms</strong>{{data.bed}}</li>
            <li><strong>Bathrooms</strong>{{data.bath}}</li>
            <li><strong>Garage</strong>{{data.garage}}</li>
            <li><strong>Basement</strong>{{data.basement}}</li>
            <li><strong>Area</strong>{{data.area}}</li>
            <li><strong>Type</strong>{{data.type}}</li>
            <li><strong>Price</strong>${{data.price}}</li>
            <li><strong>City</strong>{{data.city}}</li>
        </ul>
    </div>
</div>