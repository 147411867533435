import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AppHttpService {
  private httpOptions: any | undefined;
  constructor(
    protected httpClient: HttpClient,
  ) {
    this.setHeaders();
  }

  /**
   * @description Sets the request headers
   */
  private setHeaders() {
    this.httpOptions = {
      headers: {
        Accept: `application/json`,
        'Content-Type': `application/json`,
        // Change the content type accordingly later
      },
      observe: "events",
    };
  }

  protected get(url: string, data?: any): Observable<any> {
    const params = new HttpParams({ fromObject: data })
    return this.httpClient.get<any>(url, { params: params });
  }

  protected post(url: string, data: object | null): Observable<any> {
    return this.httpClient.post<any>(url, data, this.httpOptions);
  }

  protected patch(url: string, data: object | null): Observable<any> {
    return this.httpClient.patch<any>(url, data, this.httpOptions);
  }

  protected put(url: string, data: object | null, header?:any): Observable<any> {
    const headers = new HttpHeaders(header);
    return this.httpClient.put<any>(url, data, {headers:headers});
  }

  protected delete(url: string): Observable<any> {
    return this.httpClient.delete<any>(url, this.httpOptions);
  }
}
