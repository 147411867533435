import { Component, HostListener, OnInit } from '@angular/core';
import { PlatformService } from './core/platform/platform.service';
import { ResponsiveService } from './core/ui/responsive/responsive.service';
// import { NavigationEnd, Router } from '@angular/router';
// import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(_: UIEvent) {
    if (this.platformService.isPlatformBrowser) {
      const win = this.responsiveService.getWindow()
      if (win) {
        this.responsiveService.setInnerWidth(win.innerHeight);
      }
    }
  }
  title = 'new-front-end';
  constructor(
    private responsiveService: ResponsiveService,
    private platformService: PlatformService,
    // private router: Router,
  ) { }
  ngOnInit(): void {
    // Setting initial 
    // if (this.platformService.isPlatformBrowser) {
    const win = this.responsiveService.getWindow()
    if (win) {
      this.responsiveService.setInnerWidth(win.innerWidth);
    }
    // }
    // this.router.events.forEach(item => {
    //   if (item instanceof NavigationEnd) {
    //     const gtmTag = {
    //       event: 'page',
    //       pageName: item.url
    //     };
    //     this.gtmService.pushTag(gtmTag);
    //   }
    // });
  }
}
