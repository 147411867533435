import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from '../layout/default/default.component';

const routes: Routes = [
  {
    path: '', component: DefaultComponent, children: [
      { path: '', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
      { path: '', loadChildren: () => import('./web-page-city/web-page-city.module').then((m) => m.WebPageCityModule) },
      { path: 'contents', loadChildren: () => import('./web-page/web-page.module').then((m) => m.WebPageModule) },
      { path: 'properties', loadChildren: () => import('./property-details/property-details.module').then((m) => m.PropertyDetailsModule) },
      { path: 'properties', loadChildren: () => import('./property-search/property-search.module').then((m) => m.PropertySearchModule)},
      { path: '', loadChildren: () => import('./featured-properties/featured-properties.module').then((m) => m.FeaturedPropertiesModule)},
      { path: '', loadChildren: () => import('./home-for-rent/home-for-rent.module').then((m) => m.HomeForRentModule)},
      { path: 'applications', loadChildren: () => import('./online-application/online-application.module').then((m) => m.OnlineApplicationModule)},
      { path: '', loadChildren: () => import('./contact-us/contact-us.module').then((m) => m.ContactUsModule)},
      { path: '', loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule)}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoutesRoutingModule { }
