<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="hero-search-wrap" [ngStyle]="{'background': homeFlag ? 'rgb(248 250 255 / 70%)' : 'transparent'}">
    <div class="hero-search">
        <p *ngIf="homeFlag,else elseblock">Find Your Dream Property</p>
        <ng-template #elseblock>
            <h4 style="text-align:left;">Search Properties</h4>
        </ng-template>
    </div>
    <form [formGroup]="form" (ngSubmit)="search()">
        <div class="hero-search-content">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <!-- TODO: Need to fix the styling here -->
                        <!-- class="input-with-icon" -->
                        <div>
                            <ng-select id="alltype" class="form-control" formControlName="type"
                                placeholder="Select Any Type">
                                <ng-option value="">&nbsp;</ng-option>
                                <ng-option *ngFor="let type of propertyTypes;" [value]="type.id">{{type.name}}
                                </ng-option>
                            </ng-select>
                            <!-- <fa-icon icon="home"></fa-icon> -->
                        </div>
                        <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('type')?.dirty && form.get('type')?.invalid" class="text-left">
                            <div *ngIf="form.get('type')?.errors?.required">Type is required.</div>
                        </ngb-alert>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <!-- TODO: Need to fix the styling here -->
                        <!-- class="input-with-icon" -->
                        <div>
                            <ng-select id="cities" class="form-control" formControlName="city" placeholder="All Cities">
                                <ng-option value="">&nbsp;</ng-option>
                                <ng-option *ngFor="let city of cities;" [value]="city.id">{{city.name}}</ng-option>
                            </ng-select>
                            <!-- <fa-icon icon="briefcase"></fa-icon> -->
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('city')?.dirty && form.get('city')?.invalid" class="text-left">
                            <div *ngIf="form.get('city')?.errors?.required">City is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <div class="input-with-icon">
                            <input type="text" class="form-control" placeholder="Minimum" formControlName="minimum">
                            <i class="ti-money" style="left:15px;top:22px;position:absolute;"></i>
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('minimum')?.dirty && form.get('minimum')?.invalid" class="text-left">
                            <div *ngIf="form.get('minimum')?.errors?.required">Minimum is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                        <div class="input-with-icon">
                            <input type="text" class="form-control" placeholder="Maximum" formControlName="maximum">
                            <i class="ti-money" style="left:15px;top:22px;position:absolute;"></i>
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('maximum')?.dirty && form.get('maximum')?.invalid" class="text-left">
                            <div *ngIf="form.get('maximum')?.errors?.required">Maximum is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 sidebar-full-width">
                    <div class="form-group">
                        <!-- TODO: Need to fix the styling here -->
                        <!-- class="input-with-icon" -->
                        <div>
                            <ng-select id="bedrooms" class="form-control" formControlName="bedrooms"
                                placeholder="Bedrooms">
                                <ng-option value="">&nbsp;</ng-option>
                                <ng-option *ngFor="let bed of beds;" [value]="bed.id">{{bed.quantity}}</ng-option>
                            </ng-select>
                            <!-- <fa-icon icon="bed"></fa-icon> -->
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('bedrooms')?.dirty && form.get('bedrooms')?.invalid" class="text-left">
                            <div *ngIf="form.get('bedrooms')?.errors?.required">Bedrooms is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 sidebar-full-width">
                    <div class="form-group">
                        <!-- TODO: Need to fix the styling here -->
                        <!-- class="input-with-icon" -->
                        <div>
                            <ng-select id="bathrooms" class="form-control select2-hidden-accessible"
                                formControlName="bathrooms" placeholder="Bathrooms">
                                <ng-option value="">&nbsp;</ng-option>
                                <ng-option *ngFor="let bath of baths;" [value]="bath.id">{{bath.quantity}}</ng-option>
                            </ng-select>
                            <!-- <fa-icon icon="bath"></fa-icon> -->
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('bathrooms')?.dirty && form.get('bathrooms')?.invalid" class="text-left">
                            <div *ngIf="form.get('bathrooms')?.errors?.required">Bathrooms is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 sidebar-full-width">
                    <div class="form-group">
                        <!-- TODO: Need to fix the styling here -->
                        <!-- class="input-with-icon" -->
                        <div>
                            <ng-select id="basement" class="form-control" formControlName="basement"
                                placeholder="Basement">
                                <ng-option value="">&nbsp;</ng-option>
                                <ng-option *ngFor="let option of basements;" [value]="option.id">{{option.type}}
                                </ng-option>
                            </ng-select>
                            <!-- <fa-icon icon="parking"></fa-icon> -->
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('basement')?.dirty && form.get('basement')?.invalid" class="text-left">
                            <div *ngIf="form.get('basement')?.errors?.required">Basement is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 sidebar-full-width">
                    <div class="form-group">
                        <!-- TODO: Need to fix the styling here -->
                        <!-- class="input-with-icon" -->
                        <div>
                            <ng-select id="garage" class="form-control" formControlName="garage" placeholder="Garage">
                                <ng-option value="">&nbsp;</ng-option>
                                <ng-option *ngFor="let option of garages;" [value]="option.id">{{option.quantity}}
                                </ng-option>
                            </ng-select>
                            <!-- <fa-icon icon="warehouse"></fa-icon> -->
                        </div>
                        <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="form.get('garage')?.dirty && form.get('garage')?.invalid" class="text-left">
                            <div *ngIf="form.get('garage')?.errors?.required">Garage is required.</div>
                        </ngb-alert> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="hero-search-action">
            <ngb-alert [dismissible]="false" [type]="'danger'"
            *ngIf="oneFieldRequire" class="text-left">
            <div *ngIf="oneFieldRequire">Atleast one field is required.</div>
        </ngb-alert>
            <button type="submit" class="btn search-btn"> <i class="ti-search" style="margin-right: 10px;"></i>Search</button>
        </div>
    </form>
    <span><h3 style="margin: 5px;">OR</h3></span>
    <form [formGroup]="anyform" (ngSubmit)="anySearch()">
        <div class="hero-search-content">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <div class="input-with-icon">
                            <input type="text" class="form-control" placeholder="Any Keyword" formControlName="any">
                            <i class="ti-search" style="left:15px;top:22px;position:absolute;"></i>
                        </div>
                        <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="anyform.get('any')?.dirty && anyform.get('any')?.invalid" class="text-left">
                            <div *ngIf="anyform.get('any')?.errors?.required">Any keyword is required.</div>
                        </ngb-alert>
                    </div>
                </div>
            </div>
        </div>
        <div class="hero-search-action">
            <button  type="submit" class="btn search-btn"><i class="ti-search" style="margin-right: 10px;"></i>Search</button>
        </div>
    </form>
    <span><h3 style="margin: 5px;">OR</h3></span>
    <div class="hero-search-action">
        <button (click)="getAllProperties()"  class="btn search-btn"><i class="ti-list" style="margin-right: 10px;"></i>View All Properties</button>
    </div>
</div>

<ng-template #dangerTpl>
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z" />
    </svg>
    Operation Failed!
</ng-template>
