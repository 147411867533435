import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoListTileComponent } from './info-list-tile.component';
import { RouterModule, Routes } from '@angular/router';


@NgModule({
  declarations: [
    InfoListTileComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    InfoListTileComponent,
    RouterModule
  ]
})
export class InfoListTileModule { }
