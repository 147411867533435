<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Visiting Hours</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body">
    <ul>
        <li *ngFor="let event of scheduleData"> {{getDateWithTimeZone(event.start)}} - {{getDateWithTimeZone(event.end)}}</li>
    </ul>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-theme" (click)="activeModal.close('Close click')">Close</button>
</div>
