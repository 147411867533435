<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Schedule for {{propertyData.address}}-{{propertyData.stste}}-{{propertyData.country}}</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="close()">
        <span aria-hidden="true">×</span>
    </button>
    <p>The homes listed here are available for viewing on the date and time shown. If you don't see the home you are interested in, please contact one of our agents to schedule a showing at your convenience.</p>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="btn-group d-flex justify-content-between">
                <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Previous
                </div>
                <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate" style="background-color: #727b84; color: #fff;">
                    Today
                </div>
                <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()">
                    Next
                </div>
            </div>
        </div>
        <div class="col-12 pt-3 pb-1">
            <h3 class="text-center">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
        <!-- <div class="col-12">
            <div class="btn-group d-flex justify-content-between">
                <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month">
                    Month
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week">
                    Week
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                    Day
                </div>
            </div>
        </div> -->
    </div>
    <br>
    <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
        <!-- <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (eventTimesChanged)="eventTimesChanged($event)"
        > 
        </mwl-calendar-day-view> -->
      </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-theme" (click)="close()">Cancel</button>
</div>