import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { AppHttpService } from 'src/app/core/net/http/app-http.service';

@Injectable()
export class PropertySearchService extends AppHttpService {
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient) { 
    super(httpClient); 
  }

  /**
   * @returns Returns new form group with search fields
   */
  getForm() {
    return this.formBuilder.group({
      type: [null],
      city: [null],
      minimum: [null],
      maximum: [null],
      bedrooms: [null],
      bathrooms: [null],
      basement: [null],
      garage: [null],
    });
  }
  getAnyForm() {
    return this.formBuilder.group({
      any: [null, Validators.required],
    });
  }

  public getPropertyType(): Observable<any> {
    return this.get('web-api/propertyType/list').pipe(
      map((response) => response)
    );
  }

  public getCity(): Observable<any> {
    return this.get('web-api/city/list').pipe(
      map((response) => response)
    );
  }

  public getBed(): Observable<any> {
    return this.get('web-api/propertyBedroom/list').pipe(
      map((response) => response)
    );
  }

  public getBath(): Observable<any> {
    return this.get('web-api/propertyBathroom/list').pipe(
      map((response) => response)
    );
  }

  public getBasement(): Observable<any> {
    return this.get('web-api/propertyBasement/list').pipe(
      map((response) => response)
    );
  }

  public getGarage(): Observable<any> {
    return this.get('web-api/propertyGarage/list').pipe(
      map((response) => response)
    );
  }
}
