<!---------- LOOKING TO SELL OR RENT YOUR PROPERTY? Start ---------->
<section class="pb-0 pt-0">
  <div class="get-in-touch bg-primary1 pt-1">
    <div class="container">
      <div class="row between-xs middle-xs content">
        <div class="col-md-6 column p-3 p-3">
          <h4 class="mb-0" style="color: #fff">
            LOOKING TO SELL OR RENT YOUR PROPERTY?
          </h4>
        </div>
        <div class="col-md-4 p-3">
          <i class="lni-phone material-icons mat-icon-xlg mx-2" style="float: left"></i>
          <div class="column">
            <p class="mb-0">CALL US NOW</p>
            <h5 class="ws-nowrap mb-0" style="color: #fff">
              <a href="tel:2483668484" target="_blank" style="color: #fff">248-366-8484</a>
            </h5>
          </div>
        </div>
        <div class="col-md-2 p-3 text-lg-right text-center p-3">
          <a href="javascript:void(0);" class="mdc-button mdc-button--raised mx-3 mdc-ripple-upgraded">
            <span class="mdc-button__ripple"></span>
            <span class="mdc-button__label" (click)="redirectTocontactUs()" routerLinkActive="active">get in
              touch</span>
          </a>
          <!--<a href="#" class="btn btn-theme" tabindex="0">get in touch</a>-->
        </div>
      </div>
    </div>
  </div>
</section>
<!---------- LOOKING TO SELL OR RENT YOUR PROPERTY? End ---------->

<!---------- Footer Start ---------->
<footer class="dark-footer skin-dark-footer">
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6">
          <div class="footer-widget">
            <h4 class="widget-title">Contact us</h4>
            <!--<img src="../../../assets/img/logo.png" class="img-footer" alt="" />-->
            <div class="footer-add">
              
              <p>
                <i class="lni-map-marker" style="font-weight: bold; font-size: 16px; margin-right: 5px"></i>
                <b>Office Address</b>
                <span style="padding-left:22px;line-height:initial;display:block;">8081 Commerce Road, Commerce
                  Township, <br/>MI 48382</span>
              </p>
              <p>
                <i class="lni-phone" style="font-weight: bold; font-size: 16px; margin-right: 5px"></i>
                <a href="tel:2483668484">248-366-8484</a>
              </p>
              <p style="padding-bottom: 15px;border-bottom: 1px solid #5e5e5e;margin-bottom: 19px;">
                <i class="fa fa-fax" aria-hidden="true" style="font-size: 15px; margin-right: 5px"></i>
                <span><a href="fax:2483669289">Fax: 248-366-9289</a></span>
              </p>
              <p>
                <i class="lni-envelope" style="
                    font-weight: bold;
                    font-size: 16px;
                    margin-right: 5px;
                    vertical-align: middle;
                  "></i>
                <a href="mailto:info@northbloomfield.com">info@northbloomfield.com</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget ml-0 ml-md-5">
            <h4 class="widget-title">Navigations</h4>
            <ul class="footer-menu">
              <li><a href="/">Home</a></li>
              <!-- <li><a href="#">About Us</a></li> -->
              <li><a routerLink="properties/HomeForRent" routerLinkActive="active" style="cursor: pointer;">Homes for
                  Rent</a></li>
              <!-- <li><a href="#">Tenants &amp; Buyers</a></li> -->
              <!-- <li><a href="#">Owners &amp; Investors</a></li> -->
              <!-- <li><a href="#">Fair Housing Act</a></li> -->
              <li><a routerLink="applications/ContactUs" routerLinkActive="active" style="cursor: pointer;">Contact Us</a>
              </li>
              <!-- <li><a href="#">Privacy Policy</a></li>   -->
              <div *ngFor="let footer of footer">
                <li> <a routerLink="contents/{{footer.url_name}}" routerLinkActive="active" style="cursor: pointer;">
                    <i> </i>{{ footer.page_title }}</a>


              </div>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="footer-widget">
            <h4 class="widget-title">Social Media</h4>
            <ul class="footer-bottom-social">
              <!-- <li>
                <a href="https://www.facebook.com/North-Bloomfield-Properties-144376445581397/" target="_blank"><i
                    class="ti-facebook"></i></a>
              </li> -->
              <li>
                <a href="https://twitter.com/northbloomfield" target="_blank"><i class="ti-twitter"></i></a>
              </li>
              <!-- <li>
                <a href="https://www.northbloomfield.com/#" target="_blank"><i class="ti-instagram"></i></a>
              </li> -->
              <li>
                <a href="https://www.bbb.org/us/mi/commerce-twp/profile/property-management/north-bloomfield-properties-0332-28004501/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-easternmichigan.bbb.org/seals/black-seal-200-42-bbb-28004501.png" style="border: 0;" alt="North Bloomfield Properties BBB Business Review" ></a>
              </li>
            </ul>
            <div class="ht-50"></div>
            <img src="../../../assets/img/footer-symbol-area-final.png" loading="lazy" class="foot-award img-fluid"  alt="North Bloomfield Properties" width="282" height="136" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12">
          <p class="mb-0 text-center">
            © 2022 Northbloomfield, All Rights Reserved. Design By<a href="https://iovista.com/" target="_blank">
              ioVista, Inc</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
<!---------- Footer End ---------->
