<div class="agents-grid">
    <div class="jb-bookmark">
        <a class="menu__link" data-toggle="tooltip" data-original-title="Bookmark">
            <i class="ti-bookmark"></i>
        </a>
    </div>
    <!-- TODO: Need to update input for phone details -->
    <div class="agent-call">
        <a class="menu__link">
            <i class="lni-phone"></i>
        </a>
    </div>
    <div class="agents-grid-wrap">
        <div class="fr-grid-thumb">
            <a class="menu__link">
                <div class="overall-rate">{{rating ? rating : '?'}}</div>
                <img [src]="image" class="img-fluid mx-auto" width="90" height="90" alt="profile_image" loading="lazy" />
            </a>
        </div>
        <div class="fr-grid-deatil">
            <h5 class="fr-can-name"><a class="menu__link">{{name ? name : ''}}</a></h5>
            <span class="fr-position"><i class="lni-map-marker"></i>{{address ? address : ''}}</span>
            <span class="agent-type theme-cl">{{profileType ? profileType : ''}}</span>
        </div>
    </div>
    <div class="fr-grid-footer">
        <ul class="fr-grid-social">
            <li><a class="menu__link"><i class="ti-facebook"></i></a></li>
            <li><a class="menu__link"><i class="ti-twitter"></i></a></li>
            <li><a class="menu__link"><i class="ti-instagram"></i></a></li>
            <li><a class="menu__link"><i class="ti-linkedin"></i></a></li>
        </ul>
    </div>
</div>