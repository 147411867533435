import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { FlatpickrModule } from 'angularx-flatpickr';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ViewScheduleRoutingModule } from './view-schedule-routing.module';
import { ViewScheduleComponent } from './view-schedule/view-schedule.component';
import { SHARED_NG_BOOTSTRAP } from '../shared-ng-bootstrap.module';
import { ScheduleViewerComponent } from './schedule-viewer/schedule-viewer.component';

@NgModule({
  declarations: [
    ViewScheduleComponent,
    ScheduleViewerComponent
  ],
  imports: [
    CommonModule,
    ViewScheduleRoutingModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    SHARED_NG_BOOTSTRAP
  ],
  exports: [
    ViewScheduleComponent
  ]
})
export class ViewScheduleModule { }
