import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmailFriendService } from '../services/email-friend.service'
import { ToastService } from 'src/app/core/ui/notification/toast.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from 'src/app/core/ui/responsive/responsive.service';
@Component({
  selector: 'app-email-friend',
  templateUrl: './email-friend.component.html',
  styleUrls: ['./email-friend.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmailFriendService]
})
export class EmailFriendComponent implements OnInit {
  @Input() public address: any;
  @ViewChild('dangerTpl') dangerTpl: TemplateRef<any> | undefined;
  constructor(
    private emailFriendService: EmailFriendService, private toastService: ToastService, protected activatedRoute: ActivatedRoute, private modalService: NgbModal, private responsiveService: ResponsiveService
  ) { }
  propertyLink: string | undefined;
  propertyAddress: string | undefined;
  ngOnInit(): void {
    const win = this.responsiveService.getDocument()
    if (win) { this.propertyLink = win.location.href }
    this.propertyAddress = this.address
  }
  emailFriendForm: FormGroup = this.emailFriendService.getEmailFriendForm();
  emailFriend() {
    if (this.emailFriendForm.valid) {
      // console.log('emailFriendForm Form', this.emailFriendForm.value)
      if (this.propertyLink && this.propertyAddress) {
        this.emailFriendService.sendEmailToFriend(
          { name: this.emailFriendForm.value.full_name, email: this.emailFriendForm.value.your_email, friendName: this.emailFriendForm.value.friend_name, friendEmail: this.emailFriendForm.value.friend_email, comments: this.emailFriendForm.value.description, property_link: this.propertyLink, property_address: this.propertyAddress }
        ).subscribe(() => { }, (error) => { }, () => { this.modalService.dismissAll() })
      }
    } else {
      // this.showDanger();
      this.emailFriendForm.markAllAsTouched();
      const keys = Object.keys(this.emailFriendForm.controls);
      for (const key of keys) {
        this.emailFriendForm.get(key)?.markAsDirty();
      }
    }
  }

  showSuccess() {
    this.toastService.show('Operation Successful', { classname: 'bg-success text-light', delay: 10000 });
  }

  showDanger() {
    if (!this.dangerTpl) return;
    this.toastService.show(this.dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

  close() {
    this.modalService.dismissAll()
  }
}
