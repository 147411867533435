import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ViewChild, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastService } from 'src/app/core/ui/notification/toast.service';
import { PropertySearchService } from './services/property-search.service';
import { Router } from '@angular/router';
import { zip } from 'rxjs';
import { PlatformService } from 'src/app/core/platform/platform.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-property-search',
  templateUrl: './property-search.component.html',
  styleUrls: ['./property-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PropertySearchService]
})
export class PropertySearchComponent implements OnInit {
  @Input() homeFlag: boolean = false;
  @ViewChild('dangerTpl') dangerTpl: TemplateRef<any> | undefined;
  propertyTypes: { id: number, name: string }[] = [];
  cities: { id: number, name: string }[] = [];
  beds: { id: number, quantity: string }[] = [];
  baths: { id: number, quantity: string }[] = [];
  basements: { id: number, type: string }[] = [];
  garages: { id: number, quantity: string }[] = [];
  form: FormGroup = this.propertySearchService.getForm();
  anyform: FormGroup = this.propertySearchService.getAnyForm();
  loadingData: boolean | undefined;
  constructor(
    private propertySearchService: PropertySearchService,
    private toastService: ToastService,
    private router: Router,
    private platformService: PlatformService,
    private spinner: NgxSpinnerService,
  ) {
    this.initData();
  }
  isValid: boolean = false;
  oneFieldRequire : boolean = false
  ngOnInit(): void { }
  initData() {
    if (this.platformService.isPlatformBrowser) {
      if (this.loadingData === undefined) {
        this.loadingData = true;
        this.spinner.show()
        zip(
          this.propertySearchService.getPropertyType(),
          this.propertySearchService.getCity(),
          this.propertySearchService.getBed(),
          this.propertySearchService.getBath(),
          this.propertySearchService.getBasement(),
          this.propertySearchService.getGarage()
        ).subscribe(([propertyTypes, cities, beds, baths, basements, garages]) => {
          this.loadingData = false;
          this.propertyTypes = propertyTypes.data.map((element: any) => {
            return { id: element.id, name: element.name }
          })
          this.cities = cities.data.map((element: any) => {
            return { id: element.id, name: element.name }
          })
          this.beds = beds.data.map((element: any) => {
            return { id: element.id, quantity: element.quantity }
          })
          this.baths = baths.data.map((element: any) => {
            return { id: element.id, quantity: element.quantity }
          })
          this.basements = basements.data.map((element: any) => {
            return { id: element.id, type: element.type }
          })
          this.garages = garages.data.map((element: any) => {
            return { id: element.id, quantity: element.quantity }
          })
        }, (e) => {this.loadingData = false;this.spinner.hide()},()=>{this.spinner.hide()})
      }
    }
  }
  search() {

    if (this.form.valid) {
      if (this.form.controls) {
        console.log(this.form.controls)
        let theOne = Object.keys(this.form.controls).findIndex(key => this.form.controls[key].value !== null);
        if (theOne === -1) {
          console.log(theOne);
          this.oneFieldRequire = true
          this.form.markAllAsTouched();
          const keys = Object.keys(this.form.controls);
          for (const key of keys) {
            this.form.get(key)?.markAsDirty();
          }
        }else{
          this.oneFieldRequire = false
          this.router.navigate(['/properties/SearchPropertie'], { queryParams: { min_price: this.form.value.minimum, max_price: this.form.value.maximum, bed_id: this.form.value.bedrooms, bath_id: this.form.value.bathrooms, base_id: this.form.value.basement, garage_id: this.form.value.garage, city_id: this.form.value.city, pro_type_id: this.form.value.type } });
        }
      }
    } else {
      // this.showDanger();
      this.form.markAllAsTouched();
      const keys = Object.keys(this.form.controls);
      for (const key of keys) {
        this.form.get(key)?.markAsDirty();
      }
    }
  }
  anySearch() {

    if (this.anyform.valid) {
      // this.showSuccess();
      console.log('Search Form Value: ', this.anyform.value);
      this.router.navigate(['properties/SearchPropertie'],{queryParams: {searchKeyword: this.anyform.value.any}} );


      /**
       *  // TODO: process search here : mostly fetch data from server or
       * pass on the search event as an output to the parent component
      */

    } else {
      // this.showDanger();
      this.anyform.markAllAsTouched();
      const keys = Object.keys(this.anyform.controls);
      for (const key of keys) {
        this.anyform.get(key)?.markAsDirty();
      }
    }
  }

  getAllProperties(){
    this.router.navigate(['properties/SearchPropertie'] );
  }

  showStandard() {
    this.toastService.show('I am a standard toast');
  }

  showSuccess() {
    this.toastService.show('Operation Successful', { classname: 'bg-success text-light', delay: 10000 });
  }

  showDanger() {
    if (!this.dangerTpl) return;
    this.toastService.show(this.dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

}
