export const webPage = [
    {
        "url_name": "frequently-asked-questions",
        "page_title": "Frequently Asked Questions",
        "order_by": null,
    },
    {
        "url_name": "AboutUs",
        "page_title": "About Us",
        "order_by": "3",
    },
    {
        "url_name": "Tenants-Buyers",
        "page_title": "Tenant application Details",
        "order_by": "4",
    },
    {
        "url_name": "Owners-Investors",
        "page_title": "Owners & Investors  Details",
        "order_by": "5",
    },
    {
        "url_name": "FairHousingAct",
        "page_title": "Fair Housing Act ",
        "order_by": "6",
    },
    {
        "url_name": "PrivacyPolicy",
        "page_title": "Privacy Policy",
        "order_by": "8",
    }
]
