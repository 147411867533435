<!---------- Start Navigation Start ---------->
<div class="header header-light" (scroll)="onscroll()" [ngClass]="navbarfixed?'fixed':'nofixed'">
    <div class="container no-printme">
        <ul class="nav-menu align-to-right custom-navbar mobile-h-desktop-v no-printme">
            <li>
                <a routerLink="properties/HomeForRent" routerLinkActive="active" style="cursor: pointer;"
                    class="other-store-link">
                    <div class="other-store-app">
                        <div class="os-app-icon">
                            <i class="lni-home theme-cl"></i>
                        </div>
                        <div class="os-app-caps">
                            <div style="color:#000">Homes For Rent</div>
                            <!-- <span>Maintenance Request</span> -->
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="applications/ApplyOnline" class="other-store-link">
                    <div class="other-store-app">
                        <div class="os-app-icon">
                            <i class="lni-laptop-phone theme-cl"></i>
                        </div>
                        <div class="os-app-caps">
                            <div style="color:#000">Apply</div>
                            <span>Online</span>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="applications/MaintenanceRequest" (click)="redirectmaintenance()"
                    routerLinkActive="active" style="cursor: pointer;" class="other-store-link">
                    <div class="other-store-app">
                        <div class="os-app-icon">
                            <i class="lni-construction theme-cl"></i>
                        </div>
                        <div class="os-app-caps">
                            <div style="color:#000">Submit</div>
                            <span>Maintenance Request</span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
        <nav id="navigation" class="navigation" [ngClass]="{
                'navigation-landscape': screenOrientation === screenOrientationEnumInstance.Landscape,
                'navigation-portrait': screenOrientation === screenOrientationEnumInstance.Portrait
            }">
            <div class="nav-header">
                <a class="nav-brand" href="./">
                    <img src="../../../../assets/img/logo.png" class="logo img-fluid" alt="Michigan Property Management"
                        width="325" height="60" loading="lazy" /></a>
                <div *ngIf="screenOrientation === screenOrientationEnumInstance.Portrait" class="nav-toggle"
                    (click)="toggleNav()"></div>
            </div>
            <div class="nav-menus-wrapper nav-menus-wrapper-right"
                [ngStyle]="{'transition-property': navToggle ? 'none' : 'right'}"
                [ngClass]="{'nav-menus-wrapper-open': navToggle}">
                <span class="nav-menus-wrapper-close-button" (click)="toggleNav()">✕</span>
                <ul class="nav-menu nav-menu-social align-to-right mobile-v-desktop-h">
                    <li>
                        <a routerLink="properties/HomeForRent" routerLinkActive="active" style="cursor: pointer;"
                            class="other-store-link">
                            <div class="other-store-app" (click)="toggleNav()">
                                <div class="os-app-icon">
                                    <i class="lni-home theme-cl"></i>
                                </div>
                                <div class="os-app-caps">
                                    <div style="color:#000">Homes For Rent</div>
                                    <!-- <span>Maintenance Request</span> -->
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a routerLink="applications/ApplyOnline" class="other-store-link">
                            <div class="other-store-app" (click)="toggleNav()">
                                <div class="os-app-icon">
                                    <i class="lni-laptop-phone theme-cl"></i>
                                </div>
                                <div class="os-app-caps">
                                    <div style="color:#000">Apply</div>
                                    <span>Online</span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a routerLink="applications/MaintenanceRequest" (click)="redirectmaintenance()"
                            routerLinkActive="active" style="cursor: pointer;" class="other-store-link">
                            <div class="other-store-app" (click)="toggleNav()">
                                <div class="os-app-icon">
                                    <i class="lni-construction theme-cl"></i>
                                </div>
                                <div class="os-app-caps">
                                    <div style="color:#000">Submit</div>
                                    <span>Maintenance Request</span>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
                <ul class="nav-menu">
                    <!-- <li><a href="/">Home</a></li> -->
                    <!-- <li><a href="#">About Us</a></li> -->
                    <li><a routerLink="properties/HomeForRent" (click)="redirectHomeforrent()" routerLinkActive="active"
                            style="cursor: pointer;">Homes for
                            Rent</a></li>
                    <li><a routerLink="properties/favourites" (click)="redirectFavourites()" routerLinkActive="active"
                            style="cursor: pointer;">Favorites</a></li>
                    <!-- <li><a href="#">Tenants & Buyers</a></li> -->
                    <!-- <li><a href="#">Owners & Investors</a></li> -->
                    <!-- <li><a href="#">Fair Housing Act</a></li> -->
                    <li *ngFor="let footer of footer">
                        <a routerLink="contents/{{footer.url_name}}" (click)="redirect(footer.url_name)"
                            routerLinkActive="active" style="cursor: pointer;" routerLinkActive="active"><i> </i>{{
                            footer.page_title }}</a>

                    </li>
                    <li><a routerLink="applications/ContactUs" (click)="redirectTocontactUs()" routerLinkActive="active"
                            style="cursor: pointer;">Contact
                            Us</a></li>

                </ul>
            </div>
            <div *ngIf="navToggle" class="nav-overlay-panel"
                style="background-color: rgba(0, 0, 0, 0.5); display: block;" (click)="toggleNav()"></div>
        </nav>

    </div>
</div>
<!---------- End Navigation  End ---------->
<div class="clearfix"></div>