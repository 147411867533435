import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InquireService } from '../services/inquire.service'
import { ToastService } from 'src/app/core/ui/notification/toast.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveService } from 'src/app/core/ui/responsive/responsive.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-inquire',
  templateUrl: './inquire.component.html',
  styleUrls: ['./inquire.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [InquireService]
})
export class InquireComponent implements OnInit {
  @ViewChild('dangerTpl') dangerTpl: TemplateRef<any> | undefined;
  constructor(private propertyInquireService: InquireService, private toastService: ToastService, protected activatedRoute: ActivatedRoute, private responsiveService: ResponsiveService, private spinner : NgxSpinnerService) { }
  propertyLink: string | undefined;
  propertyAddress: string | undefined;
  ngOnInit(): void {
    const win = this.responsiveService.getDocument()
    if(win){  this.propertyLink=win.location.href }
    this.propertyAddress = this.activatedRoute.snapshot.params.address

  }
  inquireForm: FormGroup = this.propertyInquireService.getInquireForm();
  inquire() {
    if (this.inquireForm.valid) {
      // console.log('Inquire Form', this.inquireForm.value, this.propertyAddress)
      if (this.propertyLink && this.propertyAddress) {
        this.spinner.show()
        this.propertyInquireService.sendInquiry(
          { fullname: this.inquireForm.value.fullname, email: this.inquireForm.value.email, contact: this.inquireForm.value.phone, comment: this.inquireForm.value.description, property_link: this.propertyLink, property_address: this.propertyAddress }
        ).subscribe((result) => { }, (error) => { this.spinner.hide() }, () => {this.spinner.hide();this.showSuccess();this.inquireForm.reset()  })
      }
    } else {
      // this.showDanger();
      this.inquireForm.markAllAsTouched();
      const keys = Object.keys(this.inquireForm.controls);
      for (const key of keys) {
        this.inquireForm.get(key)?.markAsDirty();
      }
    }
  }

  showSuccess() {
    this.toastService.show(`Thank you for our interest in our property. \n
    Your message has been sent to one of our agents and someone will be getting back with you shortly.`, { classname: 'bg-success text-light', delay: 10000 });
  }

  showDanger() {
    if (!this.dangerTpl) return;
    this.toastService.show(this.dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }
}
