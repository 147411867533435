import { Component, OnInit, TemplateRef } from '@angular/core';
import { PlatformService } from 'src/app/core/platform/platform.service';
import { ToastService } from 'src/app/core/ui/notification/toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.css'],
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastContainerComponent implements OnInit {

  constructor(
    public toastService: ToastService,
    public platformService: PlatformService,
  ) { }

  ngOnInit(): void {
  }
  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

}
