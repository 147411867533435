import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplyOnlineRoutingModule } from './apply-online-routing.module';
import { ApplyOnlineComponent } from './apply-online/apply-online.component';


@NgModule({
  declarations: [
    ApplyOnlineComponent
  ],
  imports: [
    CommonModule,
    ApplyOnlineRoutingModule
  ],
  exports:[
    ApplyOnlineComponent
  ]
})
export class ApplyOnlineModule { }
