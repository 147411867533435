import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailFriendRoutingModule } from './email-friend-routing.module';
import { EmailFriendComponent } from './email-friend/email-friend.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SHARED_NG_BOOTSTRAP } from '../shared-ng-bootstrap.module';


@NgModule({
  declarations: [
    EmailFriendComponent
  ],
  imports: [
    CommonModule,
    EmailFriendRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SHARED_NG_BOOTSTRAP
  ],
  exports: [
    EmailFriendComponent
  ]
})
export class EmailFriendModule { }
