import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-apply-online',
  templateUrl: './apply-online.component.html',
  styleUrls: ['./apply-online.component.css']
})
export class ApplyOnlineComponent implements OnInit {
  @Input() data:any;
  constructor(protected router: Router) { }

  ngOnInit(): void {

  }
  applyOnline(){
    this.router.navigate(['applications/ApplyOnline', this.data.id, this.data.address]);
  }

}
