<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add To Favourite</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="close()">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div *ngIf="!flag" class="modal-body">
    Property has been succesfully added to your favorite list.

    Click on My Favorite to view all Your Favorite properties.
</div>
<div *ngIf="flag" class="modal-body">
    Property already Added in your favorite list.
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-theme" (click)="close()">Cancel</button>
</div>