import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(@Inject(PLATFORM_ID) private platformId: string) { }
  get isPlatformBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
  get isPlatformServer(): boolean {
    return isPlatformServer(this.platformId);
  }
}
