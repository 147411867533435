import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/core/ui/responsive/responsive.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {

  constructor(private responsiveService: ResponsiveService,) { }

  ngOnInit(): void {
    const win = this.responsiveService.getWindow()
        win?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
    });
  }

}
