import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { AppHttpService } from 'src/app/core/net/http/app-http.service';

@Injectable({
  providedIn: 'root'
})
export class ViewScheduleService extends AppHttpService {
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient) {
    super(httpClient);
  }

  public getPropertyScheduleList(params:{propertyId:number}): Observable<any> {
    return this.get(`web-api/property-schedule/list/${params.propertyId}`).pipe(
      map((response) => response)
    );
  }
}
