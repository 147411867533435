<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Make An Offer!</h4>
    <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="close()">
        <span aria-hidden="true">×</span>
    </button>
    <p>You are making an offer on the property located at:<b>{{address}}</b></p>
</div>

<form [formGroup]="makeOfferForm" (ngSubmit)="makeOffer()">
    <div class="modal-body">
        <div class="form-group">
            <input type="text" class="form-control" prefix="$" mask="0*.00" placeholder="Offer Amount" formControlName="offer_amount" mask="separator.2"/>
            <ngb-alert [dismissible]="false" [type]="'danger'"
                *ngIf="makeOfferForm.get('offer_amount')?.dirty && makeOfferForm.get('offer_amount')?.invalid" class="text-left">
                <div *ngIf="makeOfferForm.get('offer_amount')?.errors?.required">Offer amount is required.</div>
                <div *ngIf="makeOfferForm.get('offer_amount')?.errors?.mask">Offer amount is invalid.</div>
            </ngb-alert>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="First Name" formControlName="first_name" />
            <ngb-alert [dismissible]="false" [type]="'danger'"
                *ngIf="makeOfferForm.get('first_name')?.dirty && makeOfferForm.get('first_name')?.invalid" class="text-left">
                <div *ngIf="makeOfferForm.get('first_name')?.errors?.required">First Name is required.</div>
            </ngb-alert>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Last Name" formControlName="last_name" />
            <ngb-alert [dismissible]="false" [type]="'danger'"
                *ngIf="makeOfferForm.get('last_name')?.dirty && makeOfferForm.get('last_name')?.invalid" class="text-left">
                <div *ngIf="makeOfferForm.get('last_name')?.errors?.required">Last Name is required.</div>
            </ngb-alert>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" mask="000-000-0000" placeholder="Phone" formControlName="phone" />
            <ngb-alert [dismissible]="false" [type]="'danger'"
                *ngIf="makeOfferForm.get('phone')?.dirty && makeOfferForm.get('phone')?.invalid" class="text-left">
                <div *ngIf="makeOfferForm.get('phone')?.errors?.required">Phone is required.</div>
                <div *ngIf="makeOfferForm.get('phone')?.errors?.mask">Phone is invalid.</div>
            </ngb-alert>
        </div>
        <div class="form-group">
            <input type="text" class="form-control" placeholder="Email" formControlName="email" />
            <ngb-alert [dismissible]="false" [type]="'danger'"
                *ngIf="makeOfferForm.get('email')?.dirty && makeOfferForm.get('email')?.invalid" class="text-left">
                <div *ngIf="makeOfferForm.get('email')?.errors?.pattern">Email is invalid.</div>
                <div *ngIf="makeOfferForm.get('email')?.errors?.required">Email is required.</div>
            </ngb-alert>
        </div>
        <div class="form-group">
            <textarea class="form-control" placeholder="Comments"
                formControlName="description"></textarea>
            <ngb-alert [dismissible]="false" [type]="'danger'"
                *ngIf="makeOfferForm.get('description')?.dirty && makeOfferForm.get('description')?.invalid"
                class="text-left">
                <div *ngIf="makeOfferForm.get('description')?.errors?.required">Comments is required.</div>
            </ngb-alert>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-theme" (click)="close()">Cancel</button>
        <button type="submit" class="btn btn-theme">Submit</button>
    </div>
</form>
<ng-template #dangerTpl>
    <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
        <path
            d="M10.872 6.831l1.695 3.904 3.654-1.561-1.79 3.426 3.333.954-3.417 1.338 2.231 4.196-4.773-2.582-2.869 2.287.413-3.004-3.792-.726 2.93-1.74-1.885-2.512 3.427.646.843-4.626zm-.786-6.831l-1.665 9.119-6.512-1.228 3.639 4.851-5.548 3.294 7.108 1.361-.834 6.076 5.742-4.577 9.438 5.104-4.288-8.064 6.834-2.677-6.661-1.907 3.25-6.22-6.98 2.982-3.523-8.114z" />
    </svg>
    Operation Failed!
</ng-template>