import { Component, OnInit, ChangeDetectionStrategy, Input  } from '@angular/core';

@Component({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyInfoComponent implements OnInit {
  @Input() data: {
    bed: number,
    bath: number,
    garage: number,
    basement: string,
    area: number,
    city: string,
    type: string,
    price: number,
  } | undefined;
  constructor() { }

  ngOnInit(): void {
  }
  

}
