import { Component, OnInit, ChangeDetectionStrategy,Input } from '@angular/core';
import { webPage } from 'src/app/web-page.data';
import { Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  @Input() data:  { url_name: string, page_title: string }[] | undefined;

  constructor(protected router: Router,) { }


  ngOnInit(): void {
  }

  footer: { url_name: string, page_title: string }[] = webPage;

  redirect(url_name: any) {
    this.router.navigate([`contents/${url_name}`]);

  }
  redirectTocontactUs(){
    this.router.navigate([`applications/ContactUs`]);
  }
  redirectHomeforrent(){
    this.router.navigate([`properties/HomeForRent`]);
  }

}
