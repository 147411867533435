<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="sec-heading2 center">
                <div class="sec-left">
                    <h3>Find Properties By Cities</h3>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
    <div class="row find-city-list">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12" style="margin-top:10px;"
            *ngFor="let property of data; trackBy: propertyTrackByFn">
            <a [href]="property.url_name" routerLinkActive="active" style="cursor: pointer;"><i class="ti-home"
                    style="margin-right:5px;"></i>{{property.page_title}}</a>
        </div>
    </div>
</div>
