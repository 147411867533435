import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoCardComponent implements OnInit {
  @Input() image: string | undefined;
  @Input() title: string | undefined;
  @Input() content: string | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
