<section class="error-wrap">
    <div class="container">
        <div class="row justify-content-center">
            
            <div class="col-lg-6 col-md-10">
                <div class="text-center">
                    
                    <header>
                        <h1 >THANK YOU!</h1>
                    </header>
                    <p>for contacting us, we will get in touch with you soon...</p>
                    <a class="btn btn-theme" href="./">Back To Home</a>
                    
                </div>
            </div>
            
        </div>
    </div>
</section>