import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InquireRoutingModule } from './inquire-routing.module';
import { InquireComponent } from './inquire/inquire.component';
import { SHARED_NG_BOOTSTRAP } from '../shared-ng-bootstrap.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from "ngx-spinner";


@NgModule({
  declarations: [
    InquireComponent
  ],
  imports: [
    CommonModule,
    InquireRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SHARED_NG_BOOTSTRAP,
    NgxMaskModule.forRoot(),
    NgxSpinnerModule
  ],
  exports: [
    InquireComponent
  ]
})
export class InquireModule { }
