<!-- Single Property -->
<div *ngIf="!data" class="single-items">
    <div class="property_item classical-list">
        No Data
    </div>
</div>
<div *ngIf="data" class="single-items">
    <div class="property_item classical-list">
        <div class="image">
            <a (click)="propertyDetail(data)" routerLinkActive="active" style="cursor: pointer;">
                <img [src]="data.image" alt="latest property" class="img-fluid" width="380" height="218" loading="lazy">
            </a>
            <!-- <div class="sb-date">
                <span class="tag"><i class="ti-calendar"></i>{{data.createdDaysAgo}} days ago</span>
            </div> -->
            <span class="tag_t">{{data.propertyType}}</span>
        </div>
        <div class="proerty_content">
            <div class="proerty_text">
                <h4 class="captlize"><a (click)="propertyDetail(data)"  routerLinkActive="active" style="cursor: pointer;">{{data.address}}</a></h4>
                <p class="proerty_price">{{data.price | currency}}</p>
            </div>
            <div class="proerty_text"><h4 class="captlize"><a (click)="propertyDetail(data)"  routerLinkActive="active" style="cursor: pointer;">{{data.city}}</a></h4></div>
            <!-- <p class="property_add">{{data.address}}</p> -->
            <div class="property_meta">
                <div class="list-fx-features">
                    <div class="listing-card-info-icon">
                        <span class="inc-fleat inc-bed">{{data.noOfBeds}} Beds</span>
                    </div>
                    <div class="listing-card-info-icon">
                        <span class="inc-fleat inc-type">{{data.residenceType}}</span>
                    </div>
                    <div class="listing-card-info-icon">
                        <span class="inc-fleat inc-area">{{data.areaInSquareFt}} sq.ft</span>
                    </div>
                    <div class="listing-card-info-icon">
                        <span class="inc-fleat inc-bath">{{data.noOfBaths}} Bath</span>
                    </div>
                </div>
            </div>
            <!-- <div class="property_links">
                <a href="#" class="btn btn-theme">Request Info</a>
                <a (click)="propertyDetail(data)"  routerLinkActive="active" class="btn btn-theme-light">Property Detail</a>
            </div> -->
        </div>
    </div>
</div>