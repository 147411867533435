import { faHome, faDungeon, faSynagogue, faBuilding, faBed, faBath, faParking, faWarehouse, faTags, faDollarSign, faUser, faMedal, faBriefcase, faCog } from '@fortawesome/free-solid-svg-icons';

export const UsedIcons = [
    faHome,
    faDungeon,
    faSynagogue,
    faBuilding,
    faBed,
    faBath,
    faParking,
    faWarehouse,
    faTags,
    faDollarSign,
    faUser,
    faMedal,
    faBriefcase,
    faCog,
]