import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { AppHttpService } from 'src/app/core/net/http/app-http.service';

@Injectable({
  providedIn: 'root'
})
export class EmailFriendService extends AppHttpService {
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient) {
    super(httpClient);
  }

  getEmailFriendForm() {
    return this.formBuilder.group({
      full_name:[null, Validators.required],
      your_email: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      friend_name: [null, Validators.required],
      friend_email: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      description: [null, Validators.required]
    });
  }

  public sendEmailToFriend(data: { name: string, email: string, friendName: string,friendEmail:string,comments:string, property_link: string, property_address: string }): Observable<any> {
    return this.post('web-api/friendEmail/create', data);
  }
}
