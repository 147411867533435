<!---------- Top header Start ---------->
<div class="top-header">
    <div class="container">
        <div class="row">

            <div class="col-lg-6 col-md-6">
                <div class="cn-info">
                    <ul>
                        <li><i class="lni-phone"></i>248-366-8484</li>
                        <li><i class="ti-email"></i>info@northbloomfield.com</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <ul class="top-social">
                    <li><a href="https://bloom.owa.rentmanager.com" target="_blank">Owners Login</a></li>
                    <li> | </li>
                    <li><a href="https://bloom.twa.rentmanager.com" target="_blank">Tenants Login</a></li>
                    <!--<li><a href="#"><i class="lni-instagram"></i></a></li>-->
                    <!--<li><a href="#"><i class="lni-twitter"></i></a></li>-->
                </ul>
            </div>

        </div>
    </div>
    <div class="container">
        <div class="row">

            <div class="col-12 d-flex justify-center">
                <div>
                    *Please note: Our Auburn Hills office will be permanently closed on May 31, 2024. <a href="/applications/ContactUs" style="color:#fff; text-decoration: underline;">Our Commerce Township office will remain open. </a>
                </div>
            </div>
            

        </div>
    </div>
</div>
<!---------- Top header End ---------->