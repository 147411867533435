import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const CODEMESSAGE: { [key: number]: string } = {
  200: 'The server successfully returned the requested data.',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'Data deleted successfully.',
  400: 'There was an error in the request sent, and the server did not create or modify data.',
  401: 'The user does not have permission (the token, username, password is wrong).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request sent was for a record that did not exist, and the server did not operate.',
  406: 'The requested format is not available.',
  410: 'The requested resource is permanently deleted and will no longer be available.',
  422: 'When creating an object, a validation error occurred.',
  500: 'An error occurred in the server, please check the server.',
  502: 'Gateway error.',
  503: 'The service is unavailable, and the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
};

/**
 * The default HTTP interceptor, see the registration details `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) { }

  // private get notification(): NzNotificationService {
  // return this.injector.get(NzNotificationService);
  // }

  // private get http(): _HttpClient {
  // return this.injector.get(_HttpClient);
  // }

  private goTo(url: string): void {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    // this.notification.error(`Request error ${ev.status}: ${ev.url}`, errortext);
  }

  private handleData(ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.checkStatus(ev);
    // Business processing: some common operations
    switch (ev.status) {
      case 200:
        // Business-level error handling, the following is to process under the assumption that restful has a unified output format (meaning that there is a corresponding data format regardless of success or failure)
        // For example, the response content:
        //  Error content: {status: 1, msg:'illegal parameter'}
        //  Correct content: {status: 0, response: {}}
        // Then the following code snippets can be directly applied
        if (ev instanceof HttpResponse) {
          const body = ev.body;
          if (!body) {
            // this.injector.get(NzMessageService).error(body.msg);
            // Continue to throw errors to interrupt all subsequent Pipe and subscribe operations, therefore:
            // this.http.get('/').subscribe() Does not trigger
            return throwError({});
          } else {
            // Re-modify the content of `body` to the content of `response`. For most scenarios, there is no need to care about business status codes anymore
            // return of(new HttpResponse(Object.assign(ev, { body: body.response })));
            // Or still maintain the complete format
            return of(ev);
          }
        }
        break;
      case 403:
      case 404:
      // case 500:
        this.goTo(`/`);
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn(
            'Unknown errors, mostly caused by the backend does not support cross-domain CORS or invalid configuration, please contact system provider',
            ev,
          );
        }
        break;
    }
    if (ev instanceof HttpErrorResponse) {
      return throwError(ev);
    } else {
      return of(ev);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Unified server prefix
    let url = req.url;
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      if(url.includes(`https://${environment.BUCKET}`)){
        url=req.url
      }else{
        url = environment.SERVER_URL + url;
      }

    }

    const newReq = req.clone({ url });
    return next.handle(newReq).pipe(
      mergeMap((ev) => {
        // Allow unified handling of request errors
        if (ev instanceof HttpResponseBase) {
          return this.handleData(ev, newReq, next);
        }
        // If everything is normal, follow up operations
        return of(ev);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err, newReq, next)),
    );
  }
}
