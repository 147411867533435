import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileCardComponent implements OnInit {
  @Input() rating: number | undefined;
  @Input() image: string | undefined;
  @Input() name: string | undefined;
  @Input() address: string | undefined;
  @Input() profileType: string | undefined;

  @Input() facebookLink: string | undefined;
  @Input() twitterLink: string | undefined;
  @Input() instagramLink: string | undefined;
  @Input() linkedInLink: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
