import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationMapComponent implements OnInit {
  @Input() data: { lat: string, long: string, address: string } | undefined;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }
  mapUrl(data: any) {
    if(data?.address === 'North Bloomfield Properties'){
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?q=${data?data.address:''}&key=${environment.MAP_ACCESS_KEY}`)
    }else{
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?q=${data?data.lat:''},${data? data.long:''}&key=${environment.MAP_ACCESS_KEY}`)
    }
  }
}
