import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ViewChild, Input} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MakeOfferService } from '../services/make-offer.service'
import { ToastService } from 'src/app/core/ui/notification/toast.service';
import { ActivatedRoute } from '@angular/router';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from 'src/app/core/ui/responsive/responsive.service';

@Component({
  selector: 'app-make-offer',
  templateUrl: './make-offer.component.html',
  styleUrls: ['./make-offer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MakeOfferService]
})
export class MakeOfferComponent implements OnInit {
  @ViewChild('dangerTpl') dangerTpl: TemplateRef<any> | undefined;
  @Input() public address:any;
  constructor(
    private makeOfferService: MakeOfferService, private toastService: ToastService, protected activatedRoute: ActivatedRoute, private modalService: NgbModal, private responsiveService: ResponsiveService
  ) { }
  propertyLink: string | undefined;
  propertyAddress: string | undefined;
  ngOnInit(): void {
    this.propertyAddress=this.address
    const win = this.responsiveService.getDocument()
    if(win){  this.propertyLink=win.location.href }
  }
  makeOfferForm: FormGroup = this.makeOfferService.getMakeOfferForm();
  makeOffer(){
    if (this.makeOfferForm.valid) {
      // console.log('emailFriendForm Form', this.makeOfferForm.value)
      if (this.propertyLink && this.propertyAddress) {
        this.makeOfferService.makeOffer(
          { amount: this.makeOfferForm.value.offer_amount, fname: this.makeOfferForm.value.first_name, lname: this.makeOfferForm.value.last_name,email:this.makeOfferForm.value.email,phone:this.makeOfferForm.value.phone,comments:this.makeOfferForm.value.description, property_link: this.propertyLink, property_address: this.propertyAddress }
        ).subscribe(()=>{},(error)=>{},()=>{this.modalService.dismissAll()})
      }
    } else {
      // this.showDanger();
      this.makeOfferForm.markAllAsTouched();
      const keys = Object.keys(this.makeOfferForm.controls);
      for (const key of keys) {
        this.makeOfferForm.get(key)?.markAsDirty();
      }
    }
  }

  showSuccess() {
    this.toastService.show('Operation Successful', { classname: 'bg-success text-light', delay: 10000 });
  }

  showDanger() {
    if (!this.dangerTpl) return;
    this.toastService.show(this.dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

  close(){
    this.modalService.dismissAll()
  }
}
