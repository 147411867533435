import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RoutesModule } from './routes/routes.module';
import { LayoutModule } from './layout/layout.module';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { UsedIcons } from './font-awesome-icons';
// import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { DefaultInterceptor } from './core/net/interceptor/default-interceptor.service';
const INTERCEPTOR_PROVIDES = [
  { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
];
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    RoutesModule,
    LayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // GoogleTagManagerModule.forRoot({
    //   id: 'GTM-WTJLPPB'
    // })
  ],
  providers: [
    INTERCEPTOR_PROVIDES
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(...UsedIcons);
  }
}
