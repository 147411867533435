import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { AppHttpService } from 'src/app/core/net/http/app-http.service';

@Injectable({
  providedIn: 'root'
})
export class MakeOfferService extends AppHttpService {
  constructor(private formBuilder: FormBuilder, protected httpClient: HttpClient) {
    super(httpClient);
  }

  getMakeOfferForm() {
    return this.formBuilder.group({
      offer_amount:[null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
      description: [null, Validators.required]
    });
  }

  public makeOffer(data: { amount: number, fname: string, lname: string,email:string,phone:number,comments:string, property_link: string, property_address: string }): Observable<any> {
    return this.post('web-api/makeAnOffer/create', data);
  }
}
