<div class="block-wrap">
    <div class="block-header">
        <h4 class="block-title">Location</h4>
    </div>
    <div class="block-body">
        <div class="map-container">
            <!--<div id="singleMap" data-latitude="40.7427837" data-longitude="-73.11445617675781" data-mapTitle="Our Location"></div>-->
            <iframe [src]= "mapUrl(data)" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" ></iframe>
        </div>
    </div>
</div>
