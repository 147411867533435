import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { BreakPoints } from './responsive-breakpoints';
import { DOCUMENT } from '@angular/common';
export enum AppScreenOrientation {
  Portrait,
  Landscape
}
@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  /**
   * @returns The inner width of the current window
   */
  private innerWidth: number | undefined;

  /**
   * @returns The current break point of the window.
   * It is calculated based on the latest value of `innerWidth`
   */
  public currentBreakPoint: number | undefined;
  /**
   * @returns Subject maintaining the stream inputs of the updated break points - `BreakPoints`
   */
  private currentBreakPointSubject = new Subject<BreakPoints>();
  /**
   * @returns Observable for the subsequent event of break point updates - `BreakPoints`
   */
  currentBreakPoint$ = this.currentBreakPointSubject.asObservable();

  /**
   * @returns The decided screen orientation of the window.
   * It is calculated based on the latest value of `currentBreakPoint`
   */
  public screenOrientation: AppScreenOrientation | undefined;
  /**
   * @returns Subject maintaining the stream inputs of the updated screen orientations - `ScreenOrientation`
   */
  private screenOrientationSubject = new Subject<AppScreenOrientation>();
  /**
   * @returns Observable for the subsequent event of screen orientations updates - `ScreenOrientation`
   */
  screenOrientation$ = this.screenOrientationSubject.asObservable();

  constructor(@Inject(DOCUMENT) private _doc: Document) { }

  /**
   *
   * @param innerWith : number
   * @returns - Returns the calculated `breakpoint` baseed on the provided `innerWidth`
   */
  private calculateBreakPoint(innerWith: number) {
    let calculateCurrentBreakPoint = 0;
    if (innerWith <= BreakPoints.xs) {
      calculateCurrentBreakPoint = BreakPoints.xs;
    } else if (innerWith >= BreakPoints.sm && innerWith < BreakPoints.md) {
      calculateCurrentBreakPoint = BreakPoints.sm;
    } else if (innerWith >= BreakPoints.md && innerWith < BreakPoints.lg) {
      calculateCurrentBreakPoint = BreakPoints.md;
    } else if (innerWith >= BreakPoints.lg && innerWith < BreakPoints.xl) {
      calculateCurrentBreakPoint = BreakPoints.lg;
    } else if (innerWith >= BreakPoints.xl && innerWith < BreakPoints.xxl) {
      calculateCurrentBreakPoint = BreakPoints.xl;
      // } else if (innerWith >= BreakPoints.xxl && innerWith < BreakPoints.xxxl) {
      //   calculateCurrentBreakPoint = BreakPoints.xxl;
      // } else if (innerWith >= BreakPoints.xxxl) {
      //   calculateCurrentBreakPoint = BreakPoints.xxxl;
    }
    return calculateCurrentBreakPoint;
  }

  /**
   * @description - Updates the current screen `innerWidth` and hence the `currentBreakPoint`
   */
  public setInnerWidth(value: number) {
    const previousBreakPoint = this.currentBreakPoint;
    const previousScreenOrientation = this.screenOrientation;
    this.innerWidth = value;

    // Update Current Break Point
    const newCurrentBreakPoint = this.calculateBreakPoint(value);
    // Send New Break Point event to subscribers
    if (previousBreakPoint !== newCurrentBreakPoint) {
      this.currentBreakPoint = newCurrentBreakPoint;
      this.sendNewBreakPoint();
    } else {
      this.currentBreakPoint = previousBreakPoint;
    }

    // Update Screen Orientation
    const newScreenOrientation = this.calculateScreenOrientation(this.currentBreakPoint);
    // Send New Screen Orientation event to subscribers
    if (previousScreenOrientation !== newScreenOrientation) {
      this.screenOrientation = newScreenOrientation;
      this.sendNewScreenOrientation();
    } else {
      this.screenOrientation = previousScreenOrientation;
    }
  }

  /**
   * @description Updates the current `screenOrientation` based on the updated `currentBreakPoint`
   * @returns void
   */
  private calculateScreenOrientation(breakPoint: BreakPoints) {
    let screenOrientation = AppScreenOrientation.Landscape;
    if (breakPoint < BreakPoints.xxxl) {
      screenOrientation = AppScreenOrientation.Portrait;
    } else {
      screenOrientation = AppScreenOrientation.Landscape;
    }
    return screenOrientation;
  }

  /**
   * @description Sends the latest `currentBreakPoint` event in the subject stream
   * @returns  void
   */
  private sendNewBreakPoint(): void {
    if (this.currentBreakPoint === null || this.currentBreakPoint === undefined) return;
    this.currentBreakPointSubject.next(this.currentBreakPoint);
  }

  /**
   * @description Sends the latest `currentBreakPoint` event in the subject stream
   * @returns  void
   */
  private sendNewScreenOrientation(): void {
    if (this.screenOrientation === null || this.screenOrientation === undefined) return;
    this.screenOrientationSubject.next(this.screenOrientation);
  }

  getWindow(): Window | null {
    return this._doc.defaultView;
  }
  getDocument(): Document {
    return this._doc;
  }

  getLocation(): Location {
    return this._doc.location;
  }

  createElement(tag: string): HTMLElement {
    return this._doc.createElement(tag);
  }

}
